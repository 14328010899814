<template>
    <div id="app">
        <generator-top :on-generate-click="onGenerateClick"
                       :on-language-change="onLanguageChange"/>
        <generator-bottom ref="bannerContainer"/>
    </div>
</template>

<script>
    import GeneratorTop    from './components/GeneratorTop';
    import GeneratorBottom from './components/GeneratorBottom';

    export default {
        name: 'App',
        components: { GeneratorBottom, GeneratorTop },
        methods:    {
            onGenerateClick(hall, booth, lang) {
                this.$refs.bannerContainer.generate(hall, booth, lang);
            },
            onLanguageChange(lang) {
                this.$refs.bannerContainer.lang = lang;
            },
        },
    };
</script>
