import Vue   from 'vue';
import App   from './App.vue';

// scss
import './scss/app.scss';

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app');
