<template>
    <div class="banner-list" :class="{'opened': opened, 'mobile': isMobile}">
        <div class="banner-list__title" @click="onHeadClick">
            <h3>{{ title }}</h3>
            <template>
                <span>3 Banners</span>
                <i></i>
            </template>
        </div>
        <div class="banner-list__content" ref="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:  'BannerList',
        props: {
            title: {
                type:     String,
                required: true,
            },
        },
        mounted() {
            if (this.isMobile) {
                this.collapse();
            }
        },
        data() {
            return {
                opened: true,
            };
        },
        methods:  {
            onHeadClick() {
                this.opened = !this.opened;
                if (this.opened) {
                    this.expand();
                } else {
                    this.collapse();
                }
            },
            expand() {
                const el = this.$refs.content;

                const updateEl = () => {
                    el.style.height  = `${el.scrollHeight}px`;
                    el.style.opacity = '1';
                    this.opened      = true;
                };
                updateEl();
            },
            collapse() {
                const el         = this.$refs.content;
                el.style.height  = '0';
                el.style.opacity = '0';
                this.opened      = false;
            }
        },
        computed: {
            isMobile() {
                return window.innerWidth < 701;
            }
        },
    };
</script>
