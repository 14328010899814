/*
    Define styles and overwrites for single banners or banner groups
     {
        'images':    ['ed_email_de'], - Images/Banners the style will be applied to
        'fontSize':  23, - font size
        'regular':   true, - add to make text regular instead of bold
        'color':     '#028D37', - text color
        'alignment': 'left', - alignment (left, center, right)
        'position':  [20, 100], - X/Y position of the text,
        'text': {
            'en': 'Visit us in hall ${hall}, booth ${booth}', - overwrite default text for language(s)
        }
    },
*/

export default [
    {
        'images': 'ed_email_de',
        'fontSize': 20,
        'color': '#008a2e',
        'alignment': 'center',
        'position': [300, 120],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ed_email_en',
        'fontSize': 23,
        'color': '#008a2e',
        'alignment': 'center',
        'position': [300, 120],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'et_email_de',
        'fontSize': 20,
        'color': '#004698',
        'alignment': 'center',
        'position': [300, 120],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'et_email_en',
        'fontSize': 24,
        'color': '#004698',
        'alignment': 'center',
        'position': [300, 121],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'if_email_de',
        'fontSize': 13,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [595, 118],
    },
    {
        'images': 'if_email_en',
        'fontSize': 15,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [595, 115],
        'text': {
            'en': 'VISIT US\nIN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ed_facebook_de',
        'fontSize': 38,
        'color': '#008a2e',
        'alignment': 'left',
        'position': [25, 515],
        'text': {
            'de': 'BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ed_facebook_en',
        'fontSize': 44,
        'color': '#008a2e',
        'alignment': 'left',
        'position': [25, 505],
        'text': {
            'en': 'VISIT US IN\nHALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'et_facebook_de',
        'fontSize': 42,
        'color': '#004698',
        'alignment': 'left',
        'position': [30, 410],
        'text': {
            'de': 'BESUCHEN SIE\nUNS IN HALLE ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'et_facebook_en',
        'fontSize': 44,
        'color': '#004698',
        'alignment': 'left',
        'position': [30, 405],
        'text': {
            'en': 'VISIT US IN\nHALL ${hall},\nSTAND ${booth}',
        },
    },
    {
        'images': 'if_facebook_de',
        'fontSize': 40,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [1180, 555],
        'text': {
            'de': 'BESUCHEN SIE UNS\nIN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'if_facebook_en',
        'fontSize': 52,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [1180, 600],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ed_instagram_de',
        'fontSize': 41,
        'color': '#008a2e',
        'alignment': 'center',
        'position': [535, 995],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'ed_instagram_en',
        'fontSize': 49,
        'color': '#008a2e',
        'alignment': 'center',
        'position': [535, 995],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'et_instagram_de',
        'fontSize': 41,
        'color': '#004698',
        'alignment': 'center',
        'position': [530, 995],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'et_instagram_en',
        'fontSize': 56,
        'color': '#004698',
        'alignment': 'center',
        'position': [530, 1000],
        'text': {
            'de': 'BESUCHEN SIE UNS IN HALLE ${hall}, STAND ${booth}',
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
    {
        'images': 'if_instagram_de',
        'fontSize': 55,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [1055, 988],
    },
    {
        'images': 'if_instagram_en',
        'fontSize': 52,
        'color': '#122b6b',
        'alignment': 'right',
        'position': [1054, 1050],
        'text': {
            'en': 'VISIT US IN HALL ${hall}, STAND ${booth}',
        },
    },
];
